
// ToDo: Hochladen der Filialbilder überarbeiten
// ToDo: Reihenfolge der Filialbilder anpassen
// ToDo: Reihenfolge der Summary anpassen

import Vue from 'vue';
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import { FormDefinition, formInputRequired, formIsValidURL } from '../plugins/form-rules'

import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import { Branch } from '@/companyDashboardAPI/Branch';
import { BranchSummary } from "@/companyDashboardAPI/BranchSummary";
import { BrandInfos } from "@/companyDashboardAPI/BrandInfos";

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import MainCard from "@/components/MainCard.vue"
import { SinnoAppSettings } from '@/companyDashboardAPI/SinnoAppSettings';
import DialogDivider from '../components/DialogDivider.vue';
import ColorPreview from '../components/ColorPreview.vue';
import { click2DateURLCheck } from '@/functions/c2d';

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
    DialogDivider: DialogDivider,
    ColorPreview: ColorPreview,
  }
})
export default class BranchEditView extends Vue {
  @Prop({required: true})
  private readonly ApiClient!: CompanyDashboardApi;

  public loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public submittingForm = false;
  public errorMessage = '';

  private defaultBrandInfos: BrandInfos = {
    PrimaryColor: '#0062ae',
    PrimaryContrastColor: '#ffffff',
    SecondaryColor: '#e3e3e3',
    SecondaryContrastColor: '#0062ae',
    TextColor: '#2b2e34',
    TextHeaderColor: '#878787',
    TextLinkColor: '#0062ae',
  }

  public branchData: Branch = {
    branchId: 0,
    branchIdIPRO: 0,
    branchName: null,
    branchPictures: {
      Count: 0,
      Timestamp: null,
    },
    brandInfos: this.defaultBrandInfos,
    click2DateURL: null,
    hiddenInApp: false,
    language: '',
    privacyPolicyURL: null,
    summary: null,
    url: null,
    GTC: null,
    cancellingInstructions: null
  };
  private OriginalBranchData!: Branch;

  public SinnoAppSettings!: SinnoAppSettings;


  public companyLogo: string | null = '';
  public companyLogoInfo = '';
  public currentBranchLogo = '';
  public newBranchLogo = '';
  public branchPictures: BranchPictures[] = [];
  public newBranchLogoFile: File | null = null;
  public useOwnBrandInfos = false;
  public newBranchPictures: File[] = [];
  private deleteBranchPictures: number[] = [];
  public summarys: BranchSummaryEntry[] = [];

  public form: FormDefinition = {
    valid: false,
    fields: {},
    rules: {
      uploadLogo: [
        this.checkUploadLogo()
      ],
      branchName: [
        this.requiredRule()
      ],
      URL: [
        this.isValidURL()
      ],
      click2DateURL: [
        this.isValidURL()
      ],
      privacyPolicyURL: [
        this.isValidURL()
      ],
      uploadPictures: [
        this.checkUploadPictures()
      ],
      GTC: [
        this.isValidURL()
      ],
      cancellingInstructions: [
        this.isValidURL()
      ],
    }
  }

  /**
   * Called on mounted
   *
   * Load all informations for the form
   */
  private async mounted() {
    const branchDataParam = JSON.parse(this.$route.params.branchData)

    // Load branch data
    this.loadingText = this.$vuetify.lang.t(`$vuetify.branchesView_loadingBranchData`);
    for await(const branchData of this.ApiClient.getBranches()) {
      branchData.forEach(branch => {
        if (branch.branchId == branchDataParam.branchId) {
          this.branchData = JSON.parse(JSON.stringify(branch));
          this.OriginalBranchData = JSON.parse(JSON.stringify(branch));
        }
      })
    }

    // Load company settings
    this.loadingText = this.$vuetify.lang.t(`$vuetify.branchesView_loadingCompanySettings`);
    for await (const settings of this.ApiClient.getSinnoAppSettings()) {
        if (settings.brandInfos) this.defaultBrandInfos = settings.brandInfos
        this.SinnoAppSettings = settings
    }

    // Load branch logo
    this.loadingText = this.$vuetify.lang.t(`$vuetify.branchesView_loadingBranchLogo`);
    for await (let branchLogo of this.ApiClient.getBranchLogo(this.branchData)) {
      this.currentBranchLogo = (branchLogo == null) ? '' : branchLogo
    }

    // Load company logo
    for await (const companyLogo of this.ApiClient.getCompanyLogo()) {
      if (companyLogo != null) {
        this.companyLogo = companyLogo
        this.companyLogoInfo = this.$vuetify.lang.t(`$vuetify.branchesView_branchLogoDefaultCompanyLogo`)
      }

      // Load default company logo
      else {
        for await (const defaultLogo of this.ApiClient.getDefaultCompanyLogo()) {
          this.companyLogo = defaultLogo
          this.companyLogoInfo = this.$vuetify.lang.t(`$vuetify.branchesView_branchLogoDefaultSinnoLogo`)
        }
      }
    }

    // Split summary for form
    let branchSummary: BranchSummaryEntry[] = [];
    this.branchData.summary?.forEach(s => {
      branchSummary.push({
        title: s.title,
        textLines: s.textLines.join("\n")
      })
    })
    this.summarys = branchSummary

    // Get default color
    if (this.branchData.brandInfos == null) {
      this.useOwnBrandInfos = false
      this.branchData.brandInfos = this.defaultBrandInfos
    } else {
      this.useOwnBrandInfos = true
    }

    // Load branch pictures
    this.loadingText = this.$vuetify.lang.t(`$vuetify.branchesView_loadingbranchPictures`)
    const branchPictures: BranchPictures[] = [];
    for (let i = 1; i <= this.branchData.branchPictures.Count; i++) {
      for await (const picture of this.ApiClient.getBranchPresentationMedia(this.branchData, i)) {
        if (picture !== null) branchPictures.push({
          index: i,
          picture: picture,
          overlay: false
        })
      }
    }
    this.branchPictures = branchPictures

    // Stop loading spinner and show form
    this.loadingText = ''
  }

  /**
   * Add a empty summary entry
   */
  public addSummary() : void {
    this.summarys.push({
      title: '',
      textLines: ''
    })
  }

  /**
   * Remove a summary entry by index
   */
  public deleteSummary(index: number) : void {
    this.summarys.splice(index, 1)
  }

  /**
   * Save the formdata
   */
  public async save() : Promise<void> {
    this.submittingForm = true;
    this.errorMessage = '';
    let errorMessage = '';
    let branchData = JSON.parse(JSON.stringify(this.branchData));

    // Collect summary´s and split new lines to array elements
    let summary = this.summarys
    let branchSummary: BranchSummary[] = [];
    summary.forEach(s => {
      let textLines = s.textLines.split("\n");

      branchSummary.push({
        containerStyles: '',
        title: s.title,
        titleStyles: '',
        textLines: textLines
      })
    })
    branchData.summary = branchSummary

    // Clear brand infos if needed
    if (this.useOwnBrandInfos == false) branchData.brandInfos = null;

    // Upload new branch logo or delete old logo
    if (this.newBranchLogoFile) {
      await this.ApiClient.postBranchLogo(this.branchData, this.newBranchLogoFile)
        .catch((error) => {
            errorMessage += this.$vuetify.lang.t(`$vuetify.branchesView_errorMessageCantSaveNewBranchLogo`) + " ";
            console.log('error in postBranchLogo')
            console.log(error)
          })
    } else if (this.currentBranchLogo == '') {
      // ToDo: delete branch logo
    }

    // Delete branch pictures
    this.deleteBranchPictures.sort();
    let deletedPictures = 0;
    while(this.deleteBranchPictures.length) {
      let presendationMediaId = this.deleteBranchPictures[0] - deletedPictures++;
      console.log('del pic ' + presendationMediaId);


      for await (let response of this.ApiClient.deleteBranchPresentationMedia(
        this.branchData,
        presendationMediaId
      )) {
        response = new Response()
        console.log(response);

      }

      this.deleteBranchPictures.shift()
    }

    // Upload new branch pictures
    for (const pic of this.newBranchPictures) {
      await this.ApiClient.postBranchPresentationMedia(this.branchData, pic)
        .catch((error) => {
          errorMessage += this.$vuetify.lang.t(`$vuetify.branchesView_errorMessageCantSaveNewBranchPicture`) + " ";
          console.log('error in postBranchPresentationMedia')
          console.log(error)
        })
    }

    // Check on Click2Date url
    branchData.click2DateURL = click2DateURLCheck(branchData.click2DateURL);

    // Update branch data
    if (JSON.stringify(branchData) != JSON.stringify(this.OriginalBranchData)) {
      await this.ApiClient.putBranch(branchData)
        .catch((error) => {
          errorMessage += this.$vuetify.lang.t(`$vuetify.branchesView_errorMessageCantSaveBranchData`) + " ";
          console.log('error in putBranch')
          console.log(error)
        })
    }

    this.errorMessage = errorMessage;
    this.submittingForm = false;

    if (errorMessage == '') {
      this.$router.push({
        name: 'Branches'
      })
    }
  }

  /**
     * Get the current displayed logo
     */
    public getCurrentLogo() : string {
      const retVal = (this.newBranchLogo == '') ? this.currentBranchLogo ?? this.companyLogo : this.newBranchLogo

      return retVal ?? ''
    }

  /**
     * Get the primary app color
     */
    public getPrimaryColor() : string {

      return this.SinnoAppSettings.brandInfos ? this.SinnoAppSettings.brandInfos.PrimaryColor : ''
    }

  /**
   * Go back to branches view
   */
  public goBack() : void {
    this.$router.push({
      name: 'Branches'
    })
  }

  /**
   * Check on valid urls
   */
  private isValidURL() {
      return formIsValidURL(this.$vuetify.lang.t(`$vuetify.form_noValidURL`))
    }

  /**
   * Change showing branch logo
   */
  public selectedBranchLogo() : void {
    this.newBranchLogo = (!this.newBranchLogoFile) ? '' : URL.createObjectURL(this.newBranchLogoFile)
  }

  public getURLCreateObjectURL(file: File): string {
    return URL.createObjectURL(file)
  }

  /**
   * Remove branch logo and show current branch or company logo
   */
  public removeBranchLogo() : void {
    // Remove new branch logo
    if (this.newBranchLogo) {
      this.newBranchLogo = '';
      this.newBranchLogoFile = null;
    // remove current branch logo
    } else {
      this.currentBranchLogo = '';
    }

    this.selectedBranchLogo()
  }

  /**
   * Remove a branch picture
   */
  public removeBranchPicture(index: number) : void {
    this.deleteBranchPictures.push(index)

    this.branchPictures.splice(
      this.branchPictures.findIndex(o => o.index === index),
      1
    )
  }

  /**
   * Remove new branch pictures
   */
  public removeNewBranchPicture(index: number) : void {
    this.newBranchPictures.splice(index - 100, 1)
  }

  /**
   * Set default Message for required field input
   */
  private requiredRule() {
    return formInputRequired(this.$vuetify.lang.t(`$vuetify.form_required`))
  }

  /**
   * Check upload branch logo
   */
  private checkUploadLogo() {
    return (file: any) => !file || file.size < 1_000_000 || this.$vuetify.lang.t(`$vuetify.form_maxFileSize1MB`)
  }

  /**
   * Check upload pictures
   */
  private checkUploadPictures() {
    return (files: any) => !files || !files.some((file: File) => file.size > 1_000_000) || this.$vuetify.lang.t(`$vuetify.form_maxFileSize1MB`)
  }

  /**
   * Change the position of a branch picture
   */
  public moveBranchPicture(index: number) : void {
    let branchPictures = this.branchPictures

    branchPictures.splice(index + 2, 0, branchPictures[index])
    branchPictures.splice(index, 1)

    this.branchPictures = branchPictures
  }

  public cardTitle(): string {
    /*return (this.branchData.branchIdIPRO !== null) ?
      this.branchData.branchIdIPRO.toString().padStart(3, '0') + ' ' +
      this.branchData.branchName
      : ''*/
    return this.$vuetify.lang.t(`$vuetify.menu_branchLink`)
  }
}

/**
 * For a summary we need only a title and the textlines
 */
interface BranchSummaryEntry {
  title: string  | null;
  textLines: string;
}

interface BranchPictures {
  index: number;
  picture: string;
  overlay: boolean;
}
